<template>
  <div id="overview">
    <Header :current="currentTab"/>
    <div class="main-wrapper text-center mx-auto">
        <h5>POOL</h5>
        <table class="pool-table mx-auto mt-3">
            <thead>
                <th></th>
                <th>Pair</th>
                <th>Volume</th>
            </thead>
            <tr class="table-row mb-3" v-for="c in currencyList" :key="c.id">
                <td class="image-stack-wrapper">
                    <div class="image-stack">
                        <div class="image-stack__item image-stack__item--top">
                            <img class="token-swapped-one" :src="'../assets/tokens/' + c.fromCurrency + '.png'" alt="">
                        </div>
                        <div class="image-stack__item image-stack__item--bottom">
                            <img class="token-swapped-two" :src="'../assets/tokens/' + c.toCurrency + '.png'" alt="">
                        </div>
                    </div>
                </td>
                <td style="background-color:white; margin-bottom:10px;">
                    <p>{{ c.pair }}</p>
                </td>
                <td class="end-of-row">
                    <p>{{ c.volume }}</p>
                </td>
            </tr>
        </table>
    </div>
  </div>
</template>

<script>
import Header from '../components/Header.vue'

export default {
    components: {
        Header,
    },
    data: function() {
        return {
            currentTab: 'overview',
            currencyList: {},
        }
    },
    methods: {
        getPool() {
            const options = {
                method: 'GET',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: '/pub/liquiditypool',
            };
            this.axios(options)
                .then((response) => {
                    this.currencyList = response.data
                })
                .catch((error) => {
                
                });
        },
    },
    mounted() {
        this.getPool();
    }
}
</script>

<style scoped lang="scss">
    #overview {
        background-image: url(../assets/bg.png);
        padding: 25px;
        color:white;
        height: 100vh;
    }

    .main-wrapper {
        margin-top:100px;
        width:500px;
        padding: 80px 5px;
        background-color: #121b25;
        border-radius:5px;
        .pool-table {
            width: 400px;
            .gap {
                background-color: #121b25;
                height: 10px;
            }
            .table-row {
                color: #666;
                border-bottom: 10px #121b25 solid;
                .image-stack-wrapper {
                    background-color:white;
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                    margin-bottom:10px;
                    .image-stack {
                        display: grid;
                        position: relative; // imperative for the overlapping to work
                        grid-template-columns: repeat(12, 1fr);
                        width:80px;
                        .image-stack__item--top {
                            grid-column: 4 / -1;
                            grid-column: 1 / span 8;
                            grid-row: 1;
                        }

                        .image-stack__item--bottom {
                            grid-row: 1;
                            grid-column: 4 / -1;
                            padding-top: 20%; 
                            z-index: 1; // slightly arbitrary, keeps proportion once resized
                            // tells the browser to make this image on top
                        }

                        img {
                        width: 40px;
                        display: block;
                        }
                    }
                }
                .end-of-row {
                    background-color:white;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    margin-bottom:10px;
                }
            }
        }
    }
</style>